@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    font-family: "Poppins", Verdana, Geneva, Tahoma, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

img {
    pointer-events: none;
}

h2 {
    font-family: "Lexend Deca";
    font-weight: 700;
    margin: 0;
}

h3 {
    font-family: "Lexend Deca";
    font-weight: 500;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header nav {
    position: absolute;
    top: 20px;
    right: 0;
    color: white;
    width: 100%;
    display: flex;
    justify-content: right;
}

.header nav a {
    font-size: 24px;
    font-family: "Lexend Deca";
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    color: white;
}

.header nav > a > div {
    width: 150px;
}

.navText {
    display: inline-block;
    padding-bottom: 3px;
    border-bottom-color: rgba(255, 255, 255, 0);
    border-bottom-width: 2px;
    border-bottom-style: solid;
    transition: all 0.3s ease;
}

.header nav div:hover {
    border-bottom-color: rgba(255, 255, 255, 1);
}

.header img {
    filter: brightness(60%);
    width: 100vw;
    height: 400px;
    object-fit: cover;
    object-position: center 40%;
}

.header h1 {
    position: absolute;
    left: 50vw;
    top: 150px;
    width: 100vw;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 96px;
    font-family: "Lexend Deca";
    text-align: center;
}

@media screen and (max-width: 800px) {
    .header h1 {
        font-size: 10vw;
    }
}

.article {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
}

.article div {
    padding: 20px;
    max-width: 600px;
}

.article h3 {
    font-size: 42px;
    text-align: center;
    margin: 0;
}

.article p {
    font-size: 24px;
}

.article > img {
    max-height: 500px;
    max-width: 90vw;
    object-fit: contain;
}

.appList {
    width: 48%;
    text-align: center;
}

.appList header {
    font-family: "Lexend Deca";
    font-size: 24px;
    margin-bottom: 10px;
}

.appList a {
    text-decoration: none;
    color: initial;
}

.blah {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.linkButton {
    border: 2px solid black;
    background-color: white;
    padding: 10px 20px;
    margin-top: 10px;
    min-height: 40px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    font-family: "Lexend Deca";
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.linkButton:hover {
    background-color: #CCC;
}

.appList img {
    width: 100%;
}

footer {
    padding: 12px;
    text-align: center;
    font-family: "Lexend Deca";
    background-color: #EEE;
}

footer p {
    margin: 0;
}